<template>
    <div class='tastatura' ref='tastatura'>
         <el-row>
            <el-col :span="2"> <el-button class='full-width'  @click="add('!')">!</el-button> </el-col> 
            <el-col :span="2"> <el-button class='full-width'  @click="add('@')">@</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('#')">#</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('$')">$</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('%')">%</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('^')">^</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('&')">&amp;</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('*')">*</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('(')">(</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add(')')">)</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('-')">-</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('_')">_</el-button> </el-col>
            
        </el-row>
        <el-row>
            <el-col :span="2"> <el-button class='full-width'  @click="add('1')">1</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('2')">2</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('3')">3</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('4')">4</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('5')">5</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('6')">6</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('7')">7</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('8')">8</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('9')">9</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('0')">0</el-button> </el-col>
        </el-row>
        <el-row>
            <el-col :span="2"> <el-button class='full-width'  @click="add('q')">Q</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('w')">W</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('e')">E</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('r')">R</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('t')">T</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('y')">Y</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('u')">U</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('i')">I</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('o')">O</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('p')">P</el-button> </el-col>
        </el-row>
        <el-row>
            <el-col :span="2"> <el-button class='full-width'  @click="add('a')">A</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('s')">S</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('d')">D</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('f')">F</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('g')">G</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('h')">H</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('j')">J</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('k')">K</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('l')">L</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('.')">.</el-button> </el-col>
        </el-row>
        <el-row>
            <el-col :span="2"> <el-button class='full-width'  @click="add('z')">Z</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('x')">X</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('c')">C</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('v')">V</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('b')">B</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('n')">N</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('m')">M</el-button> </el-col>
            <el-col :span="2"> <el-button class='full-width'  @click="add('?')">?</el-button> </el-col>
            <el-col :span="4"> <el-button class='full-width'  @click="backspace_key()">STERGE</el-button> </el-col>
        </el-row>
        <el-row>
            <el-col :span="24"> <el-button class='full-width'  @click="add(' ')">Spatiu</el-button> </el-col>
        </el-row>
        <div class='close-btn'>
            <el-button size='mini' @click='hide_me()'>X</el-button>
        </div>
    </div>
</template>

<script>

import settings from "@/backend/LocalSettings";
export default {
    name: 'TitluPagina',
    
    data () {
        return {
        }
    },
    methods:
    {
        add: function(key){  
            var el   = settings.CurrentInputTextFieldForKeyboard
            el.value += key;
            
            el.dispatchEvent(new Event('input'))
        },
        backspace_key(){
            var el   = settings.CurrentInputTextFieldForKeyboard
            el.value = el.value.substring( 0, el.value.length - 1 );
        },
        show_me()
        {
            this.$refs['tastatura'].style.display = 'block';
        },
        hide_me(){
            this.$refs['tastatura'].style.display = 'none';
        }
    }, 
    mounted()
    {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

    .close-btn{
        position: absolute;
        right: 20px;
        top: 0px;
        width: 20px;
        height: 20px;
    }
    .tastatura{
        display: none;
        position: fixed;
        width: 100%;
        height: auto;
        bottom: 0px;
        left: 0px;
        background: white;
        border: 1px solid red;
        z-index: 9999999;
    }
</style>
