import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/'                                         , name: 'home'                      , component: () => import( './pages/Login.vue'                                              ) },
        { path: '/bearer'                                   , name: 'bearer'                    , component: () => import( './pages/Bearer.vue'                                             ) },
        { path: '/dashboard'                                , name: 'dashboard'                 , component: () => import( './pages/Dashboard.vue'                                          ) },
        { path: '/categorii-drepturi'                       , name: 'categorii-drepturi'        , component: () => import ('./pages/categorii-drepturi/Categorii-drepturi_list.vue'         ) },
        { path: '/drepturi'                                 , name: 'drepturi'                  , component: () => import ('./pages/drepturi/Drepturi_list.vue'                             ) },
        { path: '/grupuri-utilizatori'                      , name: 'grupuri-utilizatori'       , component: () => import ('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue'       ) },
        { path: '/utilizatori'                              , name: 'utilizatori'               , component: () => import ('./pages/utilizatori/Utilizatori_list.vue'                       ) },
        { path: '/categorii_roti'                           , name: 'categorii_roti'            , component: () => import( './pages/categorii_roti/Categorii_roti_list.vue'                 ) },
        { path: '/checklist'                                , name: 'checklist'                 , component: () => import( './pages/checklist/Checklist_list.vue'                           ) },
        { path: '/verificari_checklist'                     , name: 'verificari_checklist'      , component: () => import( './pages/verificari_checklist/Verificari_checklist_list.vue'     ) },
        { path: '/clienti'                                  , name: 'clienti'                   , component: () => import( './pages/clienti/Clienti_list.vue'                               ) },
        { path: '/devize'                                   , name: 'devize'                    , component: () => import( './pages/devize/Devize_list.vue'                                 ) },
        { path: '/facturi'                                  , name: 'facturi'                   , component: () => import( './pages/facturi/Facturi_list.vue'                               ) },
        { path: '/fisa_intrare'                             , name: 'fisa_intrare'              , component: () => import( './pages/fisa_intrare/Fisa_intrare_list.vue'                     ) },
        { path: '/fisa_intrare_settings'                    , name: 'fisa_intrare_settings'     , component: () => import( './pages/fisa_intrare/Fisa_intrare_settings.vue'                 ) },
        { path: '/masini'                                   , name: 'masini'                    , component: () => import( './pages/masini/Masini_list.vue'                                 ) },
        { path: '/produse'                                  , name: 'produse'                   , component: () => import( './pages/produse/Produse_list.vue'                               ) },
        { path: '/programari'                               , name: 'programari'                , component: () => import( './pages/programari/Programari_list.vue'                         ) },
        { path: '/remindere'                                , name: 'remindere'                 , component: () => import( './pages/remindere/Remindere_list.vue'                           ) },
        { path: '/oferte'                                   , name: 'oferte'                    , component: () => import( './pages/oferte/Oferte_list.vue'                                 ) },
        { path: '/crm'                                      , name: 'crm'                       , component: () => import( './pages/CRM.vue'                                                ) },
        { path: '/serii'                                    , name: 'serii'                     , component: () => import( './pages/serii/Serii_list.vue'                                   ) },
        { path: '/servicii_ultrarapide'                     , name: 'servicii_ultrarapide'      , component: () => import( './pages/mecanic/Servicii_ultrarapide.vue'                       ) },        
        { path: '/fisa_intrare_produse'                     , name: 'fisa_intrare_produse'      , component: () => import( './pages/mecanic/Fise_intrare_produse.vue'                       ) },
        { path: '/fisa_pdf/:fisa'                           , name: 'fisa_pdf'                  , component: () => import( './pages/mecanic/Fisa_pdf.vue'                                   ) },
        { path: '/oferta_pdf/:oferta'                       , name: 'oferta_pdf'                , component: () => import( './pages/oferte/Oferta_pdf.vue'                                  ) },
        { path: '/inspectie_finala_pdf/:inspectie_finala'   , name: 'inspectie_finala_pdf'      , component: () => import( './components/inspectii_finale/Inspectie_finala_pdf.vue'         ) },
        { path: '/factura_pdf/:factura'                     , name: 'factura_pdf'               , component: () => import( './pages/facturi/Factura_pdf.vue'                                ) },
        { path: '/comanda_furnizor_pdf/:comanda'            , name: 'comanda_furnizor_pdf'      , component: () => import( './components/comenzi_furnizori/Comanda_furnizor_pdf.vue'        ) },
        { path: '/checklist_pdf/:checklist'                 , name: 'checklist_pdf'             , component: () => import( './components/checklists/Checklist_pdf.vue'                      ) },
        { path: '/inspectie_finala_settings'                , name: 'inspectie_finala_settings' , component: () => import( './components/inspectii_finale/Inspectie_finala_settings.vue'    ) },
        { path: '/fisa_risc_pdf/:id'                        , name: 'fisa_risc_pdf'             , component: () => import( './pages/mecanic/Fisa_risc_pdf.vue'                              ) },
        { path: '/tip_produse_rapide'                       , name: 'tip_produse_rapide'        , component: () => import( './pages/tip_produse_rapide/Tip_produse_rapide_list.vue'         ) },        
        { path: '/fisa_confirmare_semnatura'                , name: 'fisa_confirmare_semnatura' , component: () => import( './pages/fisa_intrare/Fisa_confirmare_semnatura.vue'             ) },
        { path: '/hotel_anvelope_semnatura'                 , name: 'hotel_anvelope_semnatura'  , component: () => import( './components/hotel_anvelope/TireHotel_confirmare_semnatura.vue' ) },
        { path: '/reports/car-count'                        , name: 'car-count'                 , component: () => import( './pages/reports/car-count.vue'                                  ) },
        { path: '/reports/salary'                           , name: 'salary'                    , component: () => import( './pages/reports/salary.vue'                                     ) },
        { path: '/hotel_anvelope'                           , name: 'hotel_anvelope'            , component: () => import( './pages/Hotel_anvelope.vue'                                     ) },
        { path: '/sms_template'                             , name: 'sms_template'              , component: () => import( './pages/sms_template/SMS_template_list.vue'                     ) },        
        { path: '/preturi_depozitare_roti'                  , name: 'preturi_depozitare_roti'   , component: () => import( './pages/preturi_depozitare_roti/Preturi_depozitare_roti_list.vue') }        
    ]
})
   