import Vue from "vue";

export default {
    version: "8.8",
    BASE_URL: 'https://myautoserv.com/api/index.php/',
    CurrentInputTextFieldForKeyboard: null,

    set_token: function(newToken) {
        window.localStorage.setItem("erp-pitcar-token", newToken);
        Vue.http.headers.common["Token"] = newToken;
    },

    set_bearer: function(bearer) {
        window.localStorage.setItem("erp-pitcar-bearer", bearer);
        // Vue.http.headers.common["Bearer"] = bearer;
    },

    get_bearer: function() {
        return window.localStorage.getItem("erp-pitcar-bearer");
    },

    get_token: function() {
        return window.localStorage.getItem("erp-pitcar-token");
    },

    set_drepturi: function (drepturi) {
        window.localStorage.setItem('erp-pitcar-drepturi_user', JSON.stringify(drepturi));
    },

    get_drepturi: function () {
        var drepturi = window.localStorage.getItem('erp-pitcar-drepturi_user');
        return JSON.parse(drepturi);
    },
    set_user_type: function (user_type) {
        window.localStorage.setItem('erp-pitcar-user_type', user_type);
    },

    get_user_type: function () {
        return window.localStorage.getItem('erp-pitcar-user_type');
    },

    //
    get_user_name: function(){
        return window.localStorage.getItem('erp-pitcar-user_name');
    },

    set_user_name: function(user_name){
        window.localStorage.setItem('erp-pitcar-user_name', user_name);
    },
    //

    // test tip_utilizator
    set_tip_utilizator: function (tip) {
        window.localStorage.setItem('erp-pitcar-tip_utilizator', tip);
    },

    get_tip_utilizator: function () {
        return window.localStorage.getItem('erp-pitcar-tip_utilizator');
    },

    // end

    is_logged_in: function() {
        var token = this.get_token();
        return token !== "";
    },

    logout: function() {
        this.set_token('');
        this.set_drepturi('');
    },

    verify_response: function(response) {
        if (response.body.NotLogged) {
            return false;
        }
        return true;
    },

    verify_login_and_redirect: function(vueInstance) {
        if (!this.is_logged_in()) {
            vueInstance.$router.push("/");
        }
    },
};
