<template>
    <div id="app">
        <router-view/>
        <div class='btn-tastatura'>
            <el-button circle type="success" icon="el-icon-postcard" @click='arata_tastatura()'></el-button>
        </div>
      <tastatura ref='tastatura'></tastatura>
    </div>  
</template>

<script>

    import settings from "@/backend/LocalSettings";
    import tastatura from '@/widgets/Tastatura';

    export default {
        name: 'App',
        components:{
            'tastatura': tastatura
        },
        
        methods:
        {
            async check_version()
            {
                var local_version  = settings.version;
                var server_version = await this.post("version");
                if( local_version != server_version )
                {
                    this.$notify({
                        title: 'Atentie',
                        message: 'A aparut o noua versiune - <button onclick="window.location.reload()">Upgrade</button> ',
                        dangerouslyUseHTMLString: true,
                        type: 'warning',
                        duration: 0
                    });
                }
            },
            post: async function(url, args={}){
                var response = await this.$http.post(url, args );
                return response.bodyText;
            },
            set_current_text_field_for_keyboard(){
                var el = document.activeElement;
                if( el.tagName == 'INPUT' && el.getAttribute("type") == "text")  
                {
                    settings.CurrentInputTextFieldForKeyboard = el;
                }
                // console.log(settings.CurrentInputTextFieldForKeyboard);
            },
            arata_tastatura(){
                this.$refs['tastatura'].show_me();
            }

        },
        mounted() 
        {
            //verificam daca exista o versiune mai noua
            this.check_version();
            // setInterval( this.set_current_text_field_for_keyboard, 500 );            
        }
    }
</script>

<style lang='less'>

    @albastru-deschis: #44b0cc;

    .text-albastru{
        color: @albastru-deschis;
    }

    .bg-albastru{
        background-color: @albastru-deschis;
    }

    div.titlu{
        // background-color: #0d7aa3;
        background-color: #152e33 !important;
        h1{
            color: white;
            padding: 8px 15px;
            font-family: 'Lato', sans-serif;
            font-weight: 300;
        }
        .btn-adauga{
            float: right;
            height: 72px;
            padding-left: 50px;
            padding-right: 50px;
            background: #34c2e4; /* Old browsers */
            background: -moz-linear-gradient(top, #34c2e4 0%, #42b6df 50%, #4fabda 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #34c2e4 0%,#42b6df 50%,#4fabda 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #34c2e4 0%,#42b6df 50%,#4fabda 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#34c2e4', endColorstr='#4fabda',GradientType=0 ); /* IE6-9 */
            border: none;
            border-radius: 0%;
            margin: 2px;
        }
    }


    html {
        height: 100%;
        background-position: center;
        background-size: cover;
        background-position: fixed;
        background-color: #f0b737;
        // background-image: linear-gradient(to bottom, #30c6e6 0%, rgba(84, 167, 216, 0.6) 100%), url(./assets/login-bg.jpg);
        // background-image: linear-gradient(to bottom, #30c6e6 0%, rgba(84, 167, 216, 0.6) 100%), url(./assets/logo-pitcar.png);
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    body{
        background-color: transparent !important;
    }
    .my-dialog-class .el-dialog{
        width: 95%;
        @media(min-width:768px){
            width: 50%;
        }
    }
    .full-width{
        width: 100%;
    }
    .btn-tastatura{
        position: fixed;
        // right: 25px;
        //right: 50%;
        left:25px;
        bottom: 10px;
        z-index:9000;
    }

    .my-large-dialog .el-dialog
    {
        width: 100% !important;
    }

    .bine.el-radio.is-bordered{
        border:solid 2px;
    }
    
    .bine.el-radio.is-bordered.is-checked{
        border:solid 2px; 
        border-color: #67C23A;               
        .el-radio__input.is-checked .el-radio__inner {            
            background: #67C23A;
            border-color: #67C23A;
        }
        .el-radio__input.is-checked+.el-radio__label {
            color: #67C23A;
        }       
    }
    .satisfacator.el-radio.is-bordered.is-checked{
        border:solid 2px; 
        border-color: #E6A23C;
        .el-radio__input.is-checked .el-radio__inner {            
            background: #E6A23C;
            border-color: #E6A23C;
        }
        .el-radio__input.is-checked+.el-radio__label {
            color: #E6A23C;
        }       
    }
    .urgent.el-radio.is-bordered.is-checked{
        border:solid 2px; 
        border-color: #F56C6C;
        .el-radio__input.is-checked .el-radio__inner {            
            background: #F56C6C;
            border-color: #F56C6C;
        }
        .el-radio__input.is-checked+.el-radio__label {
            color: #F56C6C;
        }       
    }

</style>
